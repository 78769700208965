import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { graphql } from 'gatsby';
import { useMediaQuery } from 'react-responsive';

import Layout from "../components/Layout";
import { TitleContainer, Title } from "../components/atoms";
import { CallToAction } from '../components/molecules';
import { PlayerFilters, Players, News, Sponsors } from "../components/organisms";

import { filterPlayers } from "../utils/filters";

const StyledNews = styled(News)`
    margin-top: 4rem;
    margin-bottom: 4rem;
`

const Teams = ({
    data: { teams, allPlayers, posts, promotion, sponsors },
    pageContext: { name }
}) => {
    const [position, setPosition] = useState();
    const [players, setPlayers] = useState(allPlayers.nodes);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const isGreaterThanMobile = useMediaQuery({ query: '(min-width: 769px)' });

    const handleToggleClick = () => setIsFilterOpen(!isFilterOpen);
    const handlePositionClick = (event) => {
        event.preventDefault();
        setPosition(event.target.value !== "all" ? event.target.value : undefined);
    }

    useEffect(() => {
        setPlayers(filterPlayers(allPlayers.nodes, position));

    }, [allPlayers, position])

    return (
        <Layout>
            <PlayerFilters
                currentTeam={name}
                selectedPosition={position}
                teams={teams.nodes}
                handleToggleClick={handleToggleClick}
                handlePositionClick={handlePositionClick}
                hasPlayers={!!players.length}
                isGreaterThanMobile={isGreaterThanMobile}
                isOpen={isFilterOpen}
            />
            <TitleContainer maxWidth="73.125em" gutter>
                <Title as="h1" noMargin>{name}</Title>
            </TitleContainer>
            {!!players.length && (
                <Players players={players} />
            )}
            {!!posts.nodes.length && (
                <StyledNews articles={posts.nodes} showHeader />
            )}
            {!!promotion.nodes.length && (
                <CallToAction
                    title={promotion.nodes[0].title}
                    text={promotion.nodes[0].content}
                    link={promotion.nodes[0].linkToContent.wpInternal.uri || promotion.nodes[0].linkToContent.external}
                />
            )}
            {!!sponsors.nodes.length && (
                <Sponsors sponsors={sponsors.nodes} />
            )}
        </Layout>
    )
}

export const query = graphql`
    query TeamQuery($id: String) {
        teams: allWpTeam {
            nodes {
                id
                name
                uri
            }
        }
        allPlayers: allWpPlayer(
            filter: {teams: {nodes: {elemMatch: {id: {eq: $id}}}}}
            sort: {fields: title}
        ) {
            nodes {
                id
                title
                playerDetails {
                    profilePicture {
                        localFile {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                    position
                    kitNumber
                }
                teams {
                    nodes {
                        name
                    }
                }
            }
        }
        posts: allWpPost(
            filter: {teams: {nodes: {elemMatch: {id: {eq: $id}}}}}
            limit: 3
            sort: {fields: date, order: DESC}
        ) {
            nodes {
                id
                title
                uri
                excerpt
                teams {
                    nodes {
                        id
                        uri
                        customFields{
                            abbreviation
                        }
                    }
                }
                landscapeImage: hero {
                    image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        altText
                    }
                }
                portraitImage: hero {
                    image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    transformOptions: {cropFocus: CENTER}
                                    aspectRatio: 0.9
                                )
                            }
                        }
                        altText
                    }
                }
            }
        }
        promotion: allWpPromotion(
            filter: {teams: {nodes: {elemMatch: {id: {eq: $id}}}}} 
            sort: {fields: date, order: DESC} 
            limit: 1
        ) {
            nodes {
                title
                content
                linkToContent {
                    wpInternal {
                        ... on WpPost {
                            uri
                        }
                        ... on WpPage {
                            uri
                        }
                        ... on WpFixture {
                            uri
                        }
                    }
                    external
                }
            }
        }
        sponsors: allWpSponsor(
            filter: {teams: {nodes: {elemMatch: {id: {eq: $id}}}}}
            sort: {order: DESC, fields: date}, 
            limit: 8
        ) {
            nodes {
                id
                hero {
                    image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        altText
                    }
                }
                linkToContent {
                    external
                }
            }
        }
    }
`

export default Teams